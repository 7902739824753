import React from 'react';
import './Navigation.css'; // Make sure to create and import a CSS file for navigation styles

const Navigation = () => {
  return (
    <nav className="navigation">


      <ul>
        <li><a href="#home">Home</a></li>
        <li><a href="#menu">Menu</a></li>
        <li><a href="#contact">Contact Us</a></li>
      </ul>
    </nav>
  );
};

export default Navigation;