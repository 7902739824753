import React, { useState } from 'react';
import './Menu.css';
import food from '../assets/coffeeImage.png';

const Menu = () => {
    const [selectedCategory, setSelectedCategory] = useState("HotDrinks");

    const HotDrinks = () => (
        <div>
            <div className='headerMenu'>Hot Coffee</div>
            <div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
      <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            
            <div className='headerMenu'>Hot Coffee</div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            </div>        </div>
    );

    const ColdDrinks = () => (
        <div>
            <div className='headerMenu'>Cold Drinks</div>
            <div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            
            <div className='headerMenu'>Hot Coffee</div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            </div>        </div>
    );

    const Food = () => (
        <div>
            <div className='headerMenu'>Food</div>
            <div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            
            <div className='headerMenu'>Hot Coffee</div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            </div>        </div>
    );

    const Croissant = () => (
        <div>
            <div className='headerMenu'>Croissant</div>
            <div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            
            <div className='headerMenu'>Hot Coffee</div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            </div>        </div>
    );

    const Doughnut = () => (
        <div>
            <div className='headerMenu'>Doughnut</div>
            <div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            
            <div className='headerMenu'>Hot Coffee</div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            </div>        </div>
    );

    const Retail = () => (
        <div>
            <div className='headerMenu'>Retail</div>
            <div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            
            <div className='headerMenu'>Hot Coffee</div>
        <div className='flexcontainerss'>
        <div style={{color:"white"}}>
                    
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
  
                </div>
         
                <div className='item-containerss'>
        <img src={food} alt='Item 1' className='item-images' />
        <h2 style={{color:"white"}}>Rachel's Chai      $4.50</h2>
        <p>House made chai</p>
      </div>
            </div>
            </div>
        </div>
    );

    const renderCategory = () => {
        switch (selectedCategory) {
            case 'HotDrinks':
                return <HotDrinks />;
            case 'ColdDrinks':
                return <ColdDrinks />;
            case 'Food':
                return <Food />;
            case 'Croissant':
                return <Croissant />;
            case 'Doughnut':
                return <Doughnut />;
            case 'Retail':
                return <Retail />;
            default:
                return null;
        }
    };

    return (
        <div className='Main'>
            <div className='flexcontainers'>
                <div onClick={() => setSelectedCategory('HotDrinks')} className='item-container'>
                    <div style={{ color: "white" }}>Hot Drinks</div>
                </div>
                <div onClick={() => setSelectedCategory('ColdDrinks')} className='item-container'>
                    <div style={{ color: "white" }}>Cold Drinks</div>
                </div>
                <div onClick={() => setSelectedCategory('Food')} className='item-container'>
                    <div style={{ color: "white" }}>Food</div>
                </div>
                <div onClick={() => setSelectedCategory('Croissant')} className='item-container'>
                    <div style={{ color: "white" }}>Croissant</div>
                </div>
                <div onClick={() => setSelectedCategory('Doughnut')} className='item-container'>
                    <div style={{ color: "white" }}>Doughnut</div>
                </div>
                <div onClick={() => setSelectedCategory('Retail')} className='item-container'>
                    <div style={{ color: "white" }}>Retail</div>
                </div>
            </div>
            {renderCategory()}
        </div>
    );
};

export default Menu;
