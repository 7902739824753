// Header.js
import React from 'react';
import './Header.css'; // Import the CSS file for the header
import Navigation from '../navigation/Navigation';
const Header = () => {
  return (
    <header className="header">
      <div className="logo">
      </div>
      <Navigation />
    </header>
  );
};

export default Header;
