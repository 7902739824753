import './App.css';
import landingImage from './assets/landingImage.png'
import Header from './header/Header';
import line from './assets/lineUnder.png'
import flower from './assets/line.png'
import food from './assets/food.png'
import croisant from './assets/croisant.png'
import doughnut from './assets/doughnut.png'
import typesofdrinks from './assets/typesofdrinks.png'
import Menu from './menu/Menu';
import Footer from './Footer/Footer';
function App() {
  return (
    <div className='App'>
            <Header /> {/* Add the Navigation component */}

      <div className='landing'>
      <img src={landingImage}></img>
      <div className='text-overlay'>
          <h1 className='fontGeorgian'>BEANS & BAGELS</h1>

 
          <img className='line' src={line} alt='line'></img>
          <p>Indulge in the Art of Coffee Elevation – Welcome to Beans & Bagels, where every sip is a symphony of flavors, and every visit is a journey into the extraordinary</p>
          <div className="menu-button">See Menu</div>

          </div>

      </div>

      <div>
        <div className='culinaryText'>Culinary Delights</div>
        <img src={flower}></img>
        
        <p className='savoryParagraph'>Savor perfection with our coffee shop's finest creation – an exquisite blend of meticulously crafted flavors, a testament to our unwavering commitment to excellence.</p>
        <div className='flexcontainer'>
        <div className='item-container'>

        <img src={doughnut}alt='Item 1' className='item-image' />
        <h2>Doughnut</h2>
        <p>Indulge in irresistible bliss with our doughnuts. From classic favorites to innovative delights, our unique creations promise a delectable experience that transcends the ordinary.</p>
      </div>
      <div className='item-container'>
        <img src={croisant} alt='Item 1' className='item-image' />
        <h2>Croissant</h2>
        <p>Elevate your mornings with our croissants. Immerse yourself in layers of buttery perfection – from classic to inventive twists, each bite is a journey into unparalleled flakiness and flavor.</p>
      </div>
      <div className='item-container'>
        <img src={typesofdrinks} alt='Item 1' className='item-image' />
        <h2>Types of Drinks</h2>
        <p>Experience unparalleled delight with our coffee shop's unique hot and cold drinks. From rich, aromatic brews to refreshing concoctions, each sip is a journey into exceptional flavor.</p>
      </div>
      <div className='item-container'>
        <img src={food} alt='Item 1' className='item-image' />
        <h2>Food</h2>
        <p>Satisfy your cravings with our American burgers and fries. Sink your teeth into juicy, flavorful burgers paired with golden, crispy fries – a delicious symphony of classic American comfort.</p>
      </div>

        </div>
      </div>
      <Menu/>

      <div>
        <Footer/>
      </div>
    </div>
    

  );
}

export default App;
