import './Footer.css';
import landingImage from '../assets/footerImage.png'


function Footer() {
  return (
    <div className='App'>

      <div className='landing'>
      <img style={{width:"100%"}} src={landingImage}></img>

      </div>
      <div className='text-overlays'>
          <p>Savor perfection with our coffee shop's finest creation – an exquisite blend of meticulously crafted flavors, a testament to our unwavering commitment to excellence.</p>

        <div className='titleFooter'>Location</div>
        <div className='subtitleFooter'>2601 W Leland Ave, Chicago, Illinois 60625</div>


        <div className='titleFooter'>Time</div>
        <div className='subtitleFooter'>Monday - Thursday : 7:10 AM - 1:50 PM</div>
        <div className='subtitleFooter'>Friday - Sunday : 8:10 AM - 1:50 PM</div>


        <div className='titleFooter'>Contact Us</div>

        <div className='subtitleFooter'>Phone : (773) 649-0015</div>
        <div className='subtitleFooter'>Email : Bean&bagel@gmail.com</div>

        </div>
    </div>

  );
}

export default Footer;
